import React, { useState, useEffect } from 'react';
import './AiStartPageNew.css';
import { openModal, showSignUpForm, showSignInForm } from "../../redux/slices/formDetails";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { saveAiNewAsync, showAiNewresponse, createAiNewAsync } from "../../redux/slices/AiNewReducer";
import { toggleProMessageModalDuonut, updateProMessageModalMessage } from "../../redux/slices/ProMessageReducer";

const AiStartPageNew = () => {
    const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
    const { userActivities, selectedUserActivity } = useSelector((state) => state.userActivity);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [gptQuestionInput, setgptQuestionInput] = useState('');
    const [errMess, seterrMess] = useState("");
    const [upgradeMess, setupgradeMess] = useState(false);
    const [selectedPage, setselectedPage] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(true); // State to toggle sidebar
    const [selectedType, setselectedType] = useState(0);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [title, settitle] = useState('');
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (!isLoggedIn) {
            setselectedPage(1);
        } else {
            dispatch(saveAiNewAsync());
        }
    }, [isLoggedIn]);
    const aiData = useSelector(showAiNewresponse);
    const aiData1 = aiData[0];

    useEffect(() => {
        if (aiData1) {
            if (aiData1.empty_message) {
                setselectedPage(1);
            } else if (!userProAccess) {
                navigate('/aidata');
            } else {
                if (userProAccess && userAccountType === "pro" && aiData1.length >= 2) {
                    navigate('/aidata');
                } else if (userProAccess && userAccountType === "plus" && aiData1.length >= 5) {
                    navigate('/aidata');
                } else if (userAccountType === "free") {
                    navigate('/aidata');
                } else if (userProAccess && userAccountType === "enterprise" && aiData1.length >= 20) {
                    navigate('/aidata');
                } else {
                    setselectedPage(1);
                }
            }
        }
    }, [aiData1])


    const handleGPTQuestionChange = (event) => {
        setgptQuestionInput(event.target.value);
        // console.log(event);
        // console.log(event.target.value);
        if (event.keyCode === 13) {
            document.getElementById("gptBtn").click();
        }
    };

    const handleClick = () => dispatch(openModal());

    const handleGPTSubmit = () => {

        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }
        let formattedUrl = gptQuestionInput.trim();
        // Regular expression to validate URL
        const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/\S*)?$/;

        if (urlRegex.test(gptQuestionInput)) {
            console.log('URL is valid:', gptQuestionInput);
        } else {
            seterrMess("Enter valid URL");
            setTimeout(() => {
                seterrMess("");
            }, 3000); //miliseconds
            return null;
        }

        // let formattedUrl = gptQuestionInput.trim(); // Trim any extra spaces
        // Check if URL starts without "https://"
        if (!formattedUrl.startsWith('https://') && !formattedUrl.startsWith('http://')) {
            console.log('Adding "https://" to the URL');
            formattedUrl = `https://${formattedUrl}`;
        }

        console.log('Validated URL:', formattedUrl);

        setselectedPage(2);
        let timeoutId = setTimeout(() => {
            setselectedPage(3)
        }, 5000); //miliseconds

        // var ai_Data = {
        //     websiteUrl: formattedUrl,
        // }
        const formData = new FormData();
        formData.append('websiteUrl', formattedUrl);

        dispatch(createAiNewAsync(formData)).then((res) => {
            console.log(res);
            if (res.status === 201) {
                window.location = "/aidata";
            } else if (res.status === "error") {
                console.log("qwerty")
                setselectedPage(1);
                // To cancel the timeout before it completes
                clearTimeout(timeoutId);
                setupgradeMess(true);
                setTimeout(() => {
                    setupgradeMess(false);
                }, 20000); //miliseconds
            }
        }).catch((e) => {
            console.log(e.message, e);
            setselectedPage(1);
            // To cancel the timeout before it completes
            clearTimeout(timeoutId);
            seterrMess("Error scanning the URL: access denied or AI access not permitted. Please contact the site administrator..");
            setTimeout(() => {
                seterrMess("");
            }, 5000); //miliseconds
        });

    };

    const handleGPTSubmit1 = () => {

        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        if (uploadedFile.length > 0) {
            console.log('valid:');
        } else {
            seterrMess("Please upload files");
            setTimeout(() => {
                seterrMess("");
            }, 5000); //miliseconds
            return null;
        }

        if (title === "") {
            seterrMess("Please enter the Title/Company name.");
            setTimeout(() => {
                seterrMess("");
            }, 5000); //miliseconds
            return null;
        }

        setselectedPage(2);
        let timeoutId = setTimeout(() => {
            setselectedPage(3)
        }, 5000); //miliseconds

        const formData = new FormData();
        uploadedFile.forEach((file, index) => {
            formData.append('uploadedFile', file); // Append each file individually
        });
        formData.append('title', title);

        console.log(formData)

        dispatch(createAiNewAsync(formData)).then((res) => {
            console.log(res);
            if (res.status === 201) {
                window.location = "/aidata";
            } else if (res.status === "error") {
                console.log("qwerty")
                setselectedPage(1);
                setUploadedFile([]);
                // To cancel the timeout before it completes
                clearTimeout(timeoutId);
                setupgradeMess(true);
                setTimeout(() => {
                    setupgradeMess(false);
                }, 20000); //miliseconds
            } else if (res?.status === "invalid") {
                setselectedPage(1);
                setUploadedFile([]);
                // To cancel the timeout before it completes
                clearTimeout(timeoutId);
                seterrMess(res?.message);
                setTimeout(() => {
                    seterrMess("");
                }, 5000); //miliseconds
            }
        }).catch((e) => {
            console.log(e.message, e);
            setUploadedFile([]);
            setselectedPage(1);
            // To cancel the timeout before it completes
            clearTimeout(timeoutId);
            seterrMess("Error scanning the file: Please upload a text-based document only.");
            setTimeout(() => {
                seterrMess("");
            }, 5000); //miliseconds
        });

    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleGetProClick = () => {
        dispatch(toggleProMessageModalDuonut(true));
        dispatch(updateProMessageModalMessage('scan more websites'));
    };

    const handleUploadFile = (event) => {
        const file = event.target.files[0];
        console.log(event);
        // console.log(event.target.value);

        if (file) {
            const fileSize = file.size; // size in bytes
            const fileSizeInKB = fileSize / 1024; // size in KB
            const totalSizeInKB = uploadedFile.reduce((acc, file) => acc + file.size / 1024, 0);
            if (uploadedFile.some(f => f.name === file.name)) {
                seterrMess("This file has already been uploaded.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); // milliseconds
                return null;
            }
            // Check if the new file plus existing files exceeds 5 MB
            if (totalSizeInKB + fileSizeInKB > 10240) {
                seterrMess("Total upload size cannot exceed 10 MB.");
                setTimeout(() => {
                    seterrMess("");
                }, 5000); // milliseconds
                return null;
            }
        }

        setUploadedFile([...uploadedFile, file]);
        event.target.value = null;
    };
    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes >= 1024 * 1024) {
            return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
        } else if (sizeInBytes >= 1024) {
            return (sizeInBytes / 1024).toFixed(2) + " KB";
        } else {
            return sizeInBytes + " Bytes";
        }
    };
    return (
        <div className='ASPN1App'>
            <div className='ASPN1template column'>
                {/* <div className={`sidebar ${sidebarOpen ? 'open' : 'closed'} col-md-2 sidebar-content`}>
                    <div className=''>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            {sidebarOpen ?
                                <img src="/assets/icons/duonut_logo.png" className="sidebar-logo" alt="Logo" />
                                : <img src="/assets/icons/duonut_icon.png" className="sidebar-logo" alt="Logo" style={{ width: "40px", heigth: "40px" }} />
                            }
                            {sidebarOpen ?
                                <button className='toggle-icon' onClick={toggleSidebar} aria-hidden="true"><i className="fa fa-angle-left" style={{ color: 'rgb(27, 75, 157)' }}></i></button>
                                : <button className='toggle-icon' onClick={toggleSidebar} aria-hidden="true"><i className="fa fa-angle-right" style={{ color: 'rgb(27, 75, 157)' }}></i></button>
                            }
                        </div>
                        <div className='sidebar-icons'>
                            {sidebarOpen ?
                                <div>
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: 'red' }}></i>
                                    <p style={{ fontSize: "14px" }}><span style={{ fontWeight: "600" }}>Upgrade now</span><br />
                                        You've reached your monthly response limit.<br />
                                        Upgrade now to unlock more conversations and new features.</p>
                                </div>
                                :
                                <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: 'rgb(27, 75, 157)' }}></i>
                            }
                            <i className="fa fa-user-circle" aria-hidden="true" style={{ color: 'rgb(27, 75, 157)' }}></i>
                        </div>
                    </div>
                </div> */}
                <div className='ASPN1container'>
                    {selectedPage === 0 && <div style={{ position: "absolute", display: "flex", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "5rem" }} />
                    </div>}
                    {selectedPage === 1 && <div className=''>
                        <div className="ASPN1container-logo-head">
                            <img className='ASPN1container-logo'
                                src={"/assets/duonut_logo.png"} alt="duonut" loading="lazy" /><br /><br />
                            <h1>Duonut Interactive Surveys</h1>
                            <h4 style={{ color: "#3498db" }}>Powered by Duonut AI</h4>
                        </div>
                        <div style={{
                            margin: "2rem",
                            lineHeight: "1.2rem",
                            // width: "30rem",
                            fontWeight: "300"
                        }}>
                            Enter your website url or upload documents upto 10mb and Duonut AI will generate a summary about your company.<br /> This summary will be used to generate questions to ask your customers.
                        </div>
                        {selectedType === 0 && (
                            <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                <div className='ASPN1AiScanType' onClick={() => setselectedType(1)}>
                                    <i className="fa fa-link" aria-hidden="true" style={{ fontSize: "1.5rem", paddingBottom: "5px" }}></i>
                                    Website URL
                                </div>
                                <div className='ASPN1AiScanType' onClick={() => setselectedType(2)}>
                                    <i className="fa fa-upload" aria-hidden="true" style={{ fontSize: "1.5rem", paddingBottom: "5px" }}></i>
                                    Upload
                                </div>
                            </div>
                        )}

                        {selectedType === 1 && (
                            <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }} className='GPT-Input'>
                                <input
                                    id="GPT-input_box"
                                    className='Ai-input_box'
                                    type="text"
                                    placeholder="Enter your company website to get started..."
                                    autoFocus
                                    // onKeyUp={e => handleGPTQuestionChange(e)}
                                    // onPaste={(e) => {
                                    //     e.preventDefault();
                                    //     const text = (e.clipboardData || window.clipboardData).getData("text").trim();
                                    //     e.target.value = text;
                                    //     handleGPTQuestionChange({ target: { value: text } });
                                    // }}
                                    // // onChange={(e) => {
                                    // //     // Trim leading and trailing spaces for typed input
                                    // //     const trimmedText = e.target.value.trim();
                                    // //     handleGPTQuestionChange({ target: { value: trimmedText } });
                                    // // }}
                                    // onKeyUp ={(e) => {
                                    //     // Trim leading and trailing spaces for typed input
                                    //     const trimmedText = e.target.value.trim();
                                    //     handleGPTQuestionChange({ target: { value: trimmedText } });
                                    // }}
                                    onKeyUp={(e) => {
                                        e.preventDefault();

                                        // Retrieve and trim the input text for both typing and pasting actions
                                        let text = e.target.value.trim();

                                        // If the event is a paste, capture the pasted text directly from clipboard data
                                        if (e.type === "paste") {
                                            text = (e.clipboardData || window.clipboardData).getData("text").trim();
                                        }

                                        // Update the input field value and trigger the change handler
                                        e.target.value = text;
                                        handleGPTQuestionChange({ target: { value: text } });
                                    }}
                                />
                                <button
                                    type='submit'
                                    id="gptBtn"
                                    style={{
                                        backgroundColor: isHovered ? "#337ab7" : "transparent",
                                        border: "1px solid rgba(149, 149, 161, 0.2)",
                                        borderRadius: "0px 5px 5px 0px",
                                        cursor: "pointer"
                                    }}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    onClick={() => handleGPTSubmit()}
                                >
                                    {isHovered ? (
                                        <img src="/assets/icons/white_svg.svg" style={{ width: "2.3rem" }} alt="duonut" loading="lazy" />
                                    ) : (
                                        <img src="/assets/icons/blue_icon.svg" style={{ width: "2.3rem" }} alt="duonut" loading="lazy" />
                                    )}
                                </button>
                            </div>
                        )}

                        {selectedType === 2 && (
                            <div>
                                <input
                                    id="GPT-input_box"
                                    className='Ai-input_box'
                                    style={{ width: "100%", maxWidth: "32.2rem", margin: "5px auto", borderRadius: "5px" }}
                                    type="text"
                                    placeholder="Enter the Title/Company here"
                                    required
                                    onKeyUp={e => settitle(e.target.value)}
                                />
                                <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }} className='GPT-Input'>
                                    <input
                                        className='Ai-input_box'
                                        id="GPT-input_box2"
                                        type="file"
                                        accept=".pdf, .doc, .docx"
                                        placeholder='Choose File'
                                        required
                                        style={{ width: "100%", margin: "10px", maxWidth: "32.2rem" }}
                                        onChange={e => handleUploadFile(e)}
                                    />
                                </div>
                                {uploadedFile.length > 0 && (
                                    <div style={{ maxWidth: "32rem", width: "100%", margin: "auto", textAlign: 'center' }}>
                                        <h3 style={{ padding: "5px", margin: "1rem 0px 1rem 0px", color: "#3498DF" }}>Uploaded File</h3>
                                        <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "left", border: "1px solid rgba(149, 149, 161, 0.2)" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: "8px", border: "1px solid rgba(149, 149, 161, 0.2)", width: "75%", fontSize: "14px" }}>File Name</th>
                                                    <th style={{ padding: "8px", border: "1px solid rgba(149, 149, 161, 0.2)", fontSize: "14px" }}>File Size</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: "16px" }}>
                                                {uploadedFile?.map((file, index) => (
                                                    <tr key={index}>
                                                        <td style={{ padding: "8px", border: "1px solid rgba(149, 149, 161, 0.2)", fontSize: "14px" }}>{file.name}</td>
                                                        <td style={{ padding: "8px", border: "1px solid rgba(149, 149, 161, 0.2)", fontSize: "14px" }}>{formatFileSize(file.size)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        )}

                        {selectedType !== 0 && (
                            <div style={{
                                cursor: "pointer", padding: "5px", display: "flex",
                                justifyContent: "center", alignItems: "center"
                            }}>
                                <button
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        backgroundColor: "transparent",
                                        color: "#3184C9",
                                        border: "1px solid #3184C9",
                                        borderRadius: "5px",
                                        padding: "15px",
                                        marginTop: "25px",
                                        width: "100px",
                                        marginRight: "20px",
                                        transition: "color 0.3s, border 0.3s" // Smooth transition for color and border
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.color = "#0E2C45"; // Dark blue on hover
                                        e.currentTarget.style.border = "1px solid #0E2C45"; // Dark blue border on hover
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.color = "#3184C9"; // Original color on leave
                                        e.currentTarget.style.border = "1px solid #3184C9"; // Original border on leave
                                    }}
                                    onClick={() => setselectedType(0)} // Go back to selection screen
                                >
                                    Back
                                </button>

                                {selectedType === 2 && ( // Show Generate button only for file upload
                                    <button
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            backgroundColor: "#3184C9",
                                            color: "white",
                                            borderRadius: "5px",
                                            padding: "15px",
                                            marginTop: "25px",
                                            width: "100px",
                                            transition: "background-color 0.3s" // Smooth transition
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0E2C45"} // Dark blue on hover
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#3184C9"} // Original color on leave
                                        onClick={() => handleGPTSubmit1()} // Function to handle submission
                                    >
                                        Generate
                                    </button>
                                )}
                            </div>
                        )}


                        {errMess && <div style={{ color: "red", padding: "5px" }}>{errMess}</div>}
                        {upgradeMess && <div style={{ margin: "1rem auto", width: "fit-content", padding: "5px", background: "#3184c9", color: 'white', textAlign: "center", borderRadius: "5px" }}>
                            Upgrade your account to scan more websites
                            <button style={{ padding: "8px 14px", marginLeft: "1rem", borderRadius: "5px", cursor: "pointer", fontWeight: "600" }}
                                onClick={() => handleGetProClick()} >Upgrade Now</button>
                        </div>}

                        {aiData1 && aiData1?.length >= 1 && <div style={{ marginTop: "1rem" }}>Already have Company Details?
                            <button className='start_page_btn'
                                onClick={() => navigate("/aidata")} >Click here</button>
                        </div>}

                    </div>}


                    {selectedPage === 2 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI is gathering insights about your company to become an expert on your products.<br /> Please avoid refreshing the page or clicking ‘back’ until we’re done.</div>
                    </div>}
                    {selectedPage === 3 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "12rem" }} />
                        <div className='loading-text'>Duonut AI will suggest questions for your customers, <br />and you can also add your own and send NPS surveys to your users.</div>
                    </div>}
                </div>

            </div>
        </div >
    );

}

export default AiStartPageNew;